import React from "react";
import image from "../assets/Frame 1707479640.png";
import Newsletter from "./Newsletter";

function WhyChooseUs() {
  return (
    <section className="bg-white">
      <div className="flex items-center my-10 justify-center text-center mx-auto py-5">
        <h2 className="text-2xl font-bold mb-4 text-gray-800">Why Choose Us</h2>
      </div>

      <div className="w-full bg-black text-white">
        <div className="container grid grid-cols-1 lg:grid-cols-2 gap-8 px-4 py-12 mx-auto">
          {/* Left Column: Content */}
          <div className="flex flex-col justify-center space-y-8 lg:px-16">
            <div className="flex flex-col gap-10">
              <div className="flex items-start">
                <svg
                  className="w-14 h-14 text-blue-500 mr-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
                <div>
                  <h3 className="text-lg font-semibold mb-1">
                    Tailored Solutions
                  </h3>
                  <p className="text-gray-600">
                    We understand that every business is unique, which is why we
                    offer customized services that fit your specific needs and
                    goals.
                  </p>
                </div>
              </div>

              <div className="flex items-start">
                <svg
                  className="w-14 h-14 text-green-500 mr-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
                <div>
                  <h3 className="text-lg font-semibold mb-1">Proven Results</h3>
                  <p className="text-gray-600">
                    With a track record of successful projects, our solutions
                    are designed to deliver tangible results and long-term
                    value.
                  </p>
                </div>
              </div>

              <div className="flex items-start">
                <svg
                  className="w-14 h-14 text-orange-500 mr-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 4.354A4 4 0 1112 12.704a4 4 0 01-3.464-3.83M8 11a4 4 0 014 4M8 11V5a4 4 0 014-4"
                  ></path>
                </svg>
                <div>
                  <h3 className="text-lg font-semibold mb-1">Expert Team</h3>
                  <p className="text-gray-600">
                    Our diverse team of experts brings a wealth of experience in
                    web development, design, marketing, and technology, ensuring
                    you get the best solutions.
                  </p>
                </div>
              </div>

              <div className="flex items-start">
                <svg
                  className="w-14 h-14 text-purple-500 mr-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
                <div>
                  <h3 className="text-lg font-semibold mb-1">
                    Client-Centric Approach
                  </h3>
                  <p className="text-gray-600">
                    We put your needs first, working closely with you to ensure
                    that our services not only meet but exceed your
                    expectations.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Right Column: Image */}
          <div className="h-full px-6">
            <img
              src={image}
              alt="Why Choose Us"
              className="w-full h-full object-cover rounded-lg shadow-lg"
            />
          </div>
        </div>
      </div>

      <Newsletter />
    </section>
  );
}

export default WhyChooseUs;
