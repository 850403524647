import React from "react";
import heroImage from "../assets/PngItem_1944111 1.png";

const HeroSection = () => {
  return (
    <div className="flex flex-col-reverse md:flex-row items-center justify-between bg-gradient-to-r from-[#b1bdd3] to-[#1E3A8A] text-white py-16 px-6 md:px-10">
      {/* Left Content */}
      <div className="flex-1 px-6 md:px-8 space-y-6">
        <h1 className="text-3xl md:text-5xl font-bold leading-snug">
          Empower Your Brand with <br /> Tailored Digital Solutions
        </h1>
        <p className="text-base md:text-lg">
          From stunning websites and compelling designs to expert marketing and
          tech solutions, we bring your vision to life. Let’s build, design, and
          grow your digital presence.
        </p>
        <button className="bg-orange-500 text-white px-6 py-3 rounded-md text-lg font-semibold hover:bg-orange-600 transition">
          Explore Now
        </button>
      </div>

      {/* Right Content */}
      <div className="flex-1 flex justify-center md:justify-end mt-6 md:mt-0">
        <img
          src={heroImage}
          alt="Digital Solutions"
          className="w-full md:w-2/3 object-contain"
        />
      </div>
    </div>
  );
};

export default HeroSection;
