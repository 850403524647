import React from "react";

const Testimonial = () => {
  return (
    <section className="w-full py-12 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl font-bold text-center text-gray-800 mb-8">
          Client Testimonial
        </h2>
        <div className="flex flex-col md:grid md:grid-cols-1 lg:grid-cols-3 gap-8 px-6">
          {/* Testimonial 1 */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <p className="text-gray-600 mb-4">
              "Qwiktechnologies exceeded our expectations! Their team delivered
              a stunning website that increased our traffic by 50%. Highly
              recommend!"
            </p>
            <div className="flex items-center">
              <img
                src="https://via.placeholder.com/50"
                alt="Client"
                className="w-12 h-12 rounded-full mr-4"
              />
              <div>
                <h4 className="font-bold text-gray-800">John Stugart</h4>
                <p className="text-sm text-gray-500">CEO, Acme Inc.</p>
              </div>
            </div>
          </div>

          {/* Testimonial 2 */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <p className="text-gray-600 mb-4">
              "Their innovative solutions and attention to detail helped our
              business grow exponentially. Exceptional service!"
            </p>
            <div className="flex items-center">
              <img
                src="https://via.placeholder.com/50"
                alt="Client"
                className="w-12 h-12 rounded-full mr-4"
              />
              <div>
                <h4 className="font-bold text-gray-800">Sarah Brown</h4>
                <p className="text-sm text-gray-500">
                  Marketing Manager, XYZ Ltd.
                </p>
              </div>
            </div>
          </div>

          {/* Testimonial 3  */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <p className="text-gray-600 mb-4">
              "Working with Qwiktechnologies was a game changer. Their expertise
              and dedication are unmatched."
            </p>
            <div className="flex items-center">
              <img
                src="https://via.placeholder.com/50"
                alt="Client"
                className="w-12 h-12 rounded-full mr-4"
              />
              <div>
                <h4 className="font-bold text-gray-800">Emily Davis</h4>
                <p className="text-sm text-gray-500">Founder, Startup Co.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
