import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Homepage from "../pages/Homepage";
import About from "../pages/About";
import Contact from "../pages/Contact";
import Portfolio from "../pages/Portfolio";
import Blog from "../pages/Blog";


const router = createBrowserRouter ([
    {
        path : "/",
        element: <App />,
        children: [
            {
                path : "",
                element : <Homepage />
            },
            {
                path: "about",
                element: <About />
            },
            {
                path: "contact",
                element: <Contact />
            },
            {
                path: "portfolio",
                element: <Portfolio />
            },
            {
                path: "blog",
                element: <Blog />
            }
        ]
    }
])

export default router;
