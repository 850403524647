import React, { useState } from "react";
import { Link } from "react-router-dom";
import image from "../assets/QWIK TECH LOGO 1.png";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaQuestionCircle,
  FaTwitter,
} from "react-icons/fa";
import { LuMessageCircle } from "react-icons/lu";

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <div>
      {/* Topbar - Hidden on mobile */}
      <div className="w-full bg-black py-4 text-white hidden sm:block">
        <div className="container mx-auto flex justify-between items-center">
          {/* FAQ Icon */}
          <div className="flex items-center space-x-2">
            <FaQuestionCircle />
            <span>FAQ</span>
          </div>

          {/* Social Icons */}
          <div className="flex space-x-4 sm:space-x-6 md:space-x-10">
            <a
              href="/"
              className="hover:text-gray-500 transition flex items-center"
            >
              <LuMessageCircle className="w-5 h-5 mr-1" />
              @Qwiktechnologies.com
            </a>
            <a href="/" className="hover:text-gray-500 transition">
              <FaLinkedin />
            </a>
            <a href="/" className="hover:text-gray-500 transition">
              <FaTwitter />
            </a>
            <a href="/" className="hover:text-gray-500 transition">
              <FaInstagram />
            </a>
            <a href="/" className="hover:text-gray-500 transition">
              <FaFacebookF />
            </a>
          </div>
        </div>
      </div>

      {/* Main Header */}
      <header className="bg-white shadow-md">
        <div className="container mx-auto py-4 px-6 flex justify-between items-center flex-wrap">
          {/* Logo */}
          <div className="flex items-center mb-4 sm:mb-0">
            <Link to="/">
              <img src={image} alt="Qwik Logo" className="" />
            </Link>
          </div>

          {/* Search Bar - Hidden on mobile */}
          <div className="relative w-full sm:w-1/2 md:w-1/3 lg:w-1/4 mb-4 sm:mb-0 hidden sm:block">
            <input
              type="text"
              placeholder="Search category"
              className="px-10 py-2 rounded-md border border-gray-300 focus:outline-none w-full"
            />
            <svg
              className="absolute left-2 top-2 text-gray-400"
              width="20"
              height="20"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0"
              ></path>
            </svg>
          </div>

          {/* Hamburger Menu for Mobile */}
          <div className="sm:hidden flex items-center">
            <button
              onClick={toggleMenu}
              className="text-gray-700 hover:text-blue-500"
            >
              {/* Hamburger Icon */}
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
            </button>
          </div>

          {/* Navigation Links - Desktop Only */}
          <div className="hidden sm:flex items-center space-x-4">
            <nav className="space-x-4 flex flex-row sm:space-x-6 md:space-x-10">
              <Link to="/" className="text-gray-700 hover:text-[#EC2A02]">
                Home
              </Link>
              <Link to="/about" className="text-gray-700 hover:text-[#EC2A02]">
                About
              </Link>
              <Link
                to="/portfolio"
                className="text-gray-700 hover:text-[#EC2A02]"
              >
                Portfolio
              </Link>
              <Link
                to="/blog"
                className="text-gray-700 hover:text-[#EC2A02]"
              >
                Blog
              </Link>
            </nav>

            {/* "Get in Touch" Button - Desktop Only */}
            <div className="ml-4">
              <Link
                to="/contact"
                className="text-gray-700 hover:text-[#EC2A02]"
              >
                <button className="border-gray-500 border text-gray-500 font-bold py-2 px-4 rounded">
                  Get in Touch
                </button>
              </Link>
            </div>
          </div>
        </div>
      </header>

      {/* Mobile Menu - Visible when `menuOpen` is true */}
      <div className={`sm:hidden ${menuOpen ? "block" : "hidden"}`}>
        <div className="bg-white p-4">
          <nav className="space-y-4">
            <Link to="/" className="text-gray-700 hover:text-blue-500 block">
              Home
            </Link>
            <Link
              to="/about"
              className="text-gray-700 hover:text-blue-500 block"
            >
              About
            </Link>
            <Link
              to="/portfolio"
              className="text-gray-700 hover:text-blue-500 block"
            >
              Portfolio
            </Link>
            <Link
              to="/blog"
              className="text-gray-700 hover:text-blue-500 block"
            >
              Blog
            </Link>
            <Link
              to="/contact"
              className="text-gray-700 hover:text-blue-500 block"
            >
              <button className="border-gray-500 border text-gray-500 font-bold py-2 px-4 rounded w-full">
                Get in Touch
              </button>
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Header;
