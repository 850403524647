import React from "react";
import { Link } from "react-router-dom";
import image from "../assets/QWIK TECH LOGO 1.png";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-[#1E1E26] text-white py-8">
      <div className="container mx-auto px-4 grid grid-cols-1 justify-between sm:grid-cols-2 lg:grid-cols-3 gap-8">
        <div className="flex flex-col">
          <Link to="/" className="flex items-center">
            <img src={image} alt="Qwik Logo" className="" />
            <h2 className="font-semibold text-lg ml-2">Qwik Technologies</h2>
          </Link>
          <p className="mt-2 text-sm sm:text-base">
            Technology needs to support human initiatives not substitute them.
            Only when you have the courage to lose sight of the shore, you will
            discover new oceans!
          </p>
          <div className="my-6">
            <h2 className="text-xl font-bold">Stay Connected</h2>
            <div className="flex space-x-3 my-3">
              <a href="/" className="hover:text-gray-500 transition w-6 h-6">
                <FaLinkedin />
              </a>
              <a href="/" className="hover:text-gray-500 transition w-6 h-6">
                <FaTwitter />
              </a>
              <a href="/" className="hover:text-gray-500 transition w-6 h-6">
                <FaInstagram />
              </a>
              <a href="/" className="hover:text-gray-500 transition w-6 h-6">
                <FaFacebookF />
              </a>
            </div>
          </div>
        </div>

        <div className="flex flex-col">
          <h3 className="text-lg font-semibold mb-2">Links</h3>
          <ul className="text-white space-y-2">
            <li>
              <a href="/" className="hover:text-white transition-colors">
                Home
              </a>
            </li>
            <li>
              <a href="/about" className="hover:text-white transition-colors">
                About
              </a>
            </li>
            <li>
              <a
                href="/services"
                className="hover:text-white transition-colors"
              >
                Services
              </a>
            </li>
            <li>
              <a
                href="/portfolio"
                className="hover:text-white transition-colors"
              >
                Portfolio
              </a>
            </li>
            <li>
              <a href="/contact" className="hover:text-white transition-colors">
                Contact
              </a>
            </li>
          </ul>
        </div>

        <div className="flex flex-col">
          <h3 className="text-lg font-semibold mb-2">Socials</h3>
          <ul className="text-white space-y-2">
            <li>
              <a href="/" className="hover:text-white transition-colors">
                Instagram
              </a>
            </li>
            <li>
              <a href="/" className="hover:text-white transition-colors">
                LinkedIn
              </a>
            </li>
            <li>
              <a href="/" className="hover:text-white transition-colors">
                Twitter
              </a>
            </li>
            <li>
              <a href="/" className="hover:text-white transition-colors">
                Facebook
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
