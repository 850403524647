import React from "react";
import Image1 from "../assets/Rectangle 94.png";
import Image2 from "../assets/Rectangle 94 (1).png";
import Image3 from "../assets/Rectangle 94 (2).png";
import Image4 from "../assets/Rectangle 94 (3).png";
import Image5 from "../assets/Rectangle 94 (4).png";
import Image6 from "../assets/Rectangle 94 (5).png";

function ServicesSection() {
  const services = [
    {
      id: 1,
      title: "Web Development that Drives Results",
      description:
        "We build fast, responsive, and secure websites that not only look great but also deliver measurable results. Our team ensures that your website performs optimally, turning visitors into loyal customers.",
      image: Image1,
    },
    {
      id: 2,
      title: "UI/UX Design for Seamless User Experience",
      description:
        "Great design goes beyond aesthetics. We craft intuitive, user-friendly interfaces that engage your audience and provide them with a seamless experience, keeping them coming back for more.",
      image: Image2,
    },
    {
      id: 3,
      title: "Graphics and Branding",
      description:
        "Your brand’s visual identity is key to making a strong first impression. Our expert designers create eye-catching logos, branding materials, and graphics that resonate with your audience and distinguish your business.",
      image: Image3,
    },
    {
      id: 4,
      title: "Digital Marketing Strategies",
      description:
        "With data-driven digital marketing campaigns, we help you reach the right audience at the right time. From SEO to social media advertising, our tailored strategies drive traffic, increase conversions, and grow your brand.",
      image: Image4,
    },
    {
      id: 5,
      title: "Expert Consultation to Guide Your Digital Journey",
      description:
        "Whether you’re just starting out or looking to scale, our team provides expert consultation to help you navigate the digital space. We offer insights and strategies that empower you to make informed decisions and achieve your business goals.",
      image: Image5,
    },
    {
      id: 6,
      title: "Tech Gadgets at Your Fingertips",
      description:
        "From the latest gadgets to essential tech tools, we offer a curated selection of products to meet your business or personal needs, all at competitive prices.",
      image: Image6,
    },
  ];

  return (
    <section className="bg-gray-100 py-12 px-6">
      <div className="container mx-auto">
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold text-gray-800">What We Do</h2>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-8">
          {services.map((service) => (
            <div
              key={service.id}
              className="bg-white rounded-lg shadow-lg overflow-hidden flex flex-col"
            >
              {/* Image Section */}
              <div className="relative w-full h-48 md:h-56 lg:h-64 overflow-hidden">
                <img
                  src={service.image}
                  alt={service.title}
                  className="w-full h-full object-cover"
                />
              </div>

              {/* Content Section */}
              <div className="p-6 flex-grow">
                <h3 className="text-xl font-bold text-gray-800 mb-2">
                  {service.title}
                </h3>
                <p className="text-gray-600 mb-4">{service.description}</p>
                <button className="border border-gray-800 text-gray-800 py-2 px-4 rounded-md transition justify-center mx-auto flex">
                  Get In Touch
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* Call to Action Section */}
        <div className="flex flex-col items-start w-full sm:w-[431px] my-6 py-6 px-6 h-[170px]">
          <h3 className="text-blue-700 font-bold mb-2">
            Ready to Elevate Your Digital Presence? <br />
            Let's work together to build something extraordinary.
          </h3>
          <button className="border border-orange-600 text-orange-800 py-2 px-4 rounded-md">
            Get Started Today
          </button>
        </div>
      </div>
    </section>
  );
}

export default ServicesSection;
