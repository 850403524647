import React from "react";

function Blog() {
  const blogs = [
    {
      title: "The Importance of Design in Web Development",
      image: "https://via.placeholder.com/600x400",
      excerpt:
        "Explore how modern design principles impact user experience and drive engagement in today's websites.",
      date: "January 15, 2025",
      author: "Qwik Tech",
    },
    {
      title: "Top 10 JavaScript Frameworks in 2025",
      image: "https://via.placeholder.com/600x400",
      excerpt:
        "Discover the most popular JavaScript frameworks for building scalable and performant web applications.",
      date: "January 10, 2025",
      author: "Qwik Tech",
    },
    {
      title: "How AI is Revolutionizing Content Creation",
      image: "https://via.placeholder.com/600x400",
      excerpt:
        "Learn how AI tools are transforming the way we create content, from blogs to videos and beyond.",
      date: "December 28, 2024",
      author: "Qwik Tech",
    },
  ];

  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Header Section */}
      <header className="bg-blue-600 text-white py-8">
        <div className="container mx-auto text-center">
          <h1 className="text-4xl font-bold">Our Blog</h1>
          <p className="mt-2 text-lg">
            Insights, tips, and trends from the world of tech and design.
          </p>
        </div>
      </header>

      {/* Blog Grid Section */}
      <section className="container mx-auto py-10 px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {blogs.map((blog, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-md overflow-hidden"
            >
              {/* Blog Image */}
              <img
                src={blog.image}
                alt={blog.title}
                className="w-full h-48 object-cover"
              />

              {/* Blog Content */}
              <div className="p-6">
                <h2 className="text-xl font-semibold text-gray-800 mb-2">
                  {blog.title}
                </h2>
                <p className="text-gray-600 mb-4">{blog.excerpt}</p>

                {/* Blog Metadata */}
                <div className="flex items-center justify-between text-sm text-gray-500 mb-4">
                  <span>{blog.date}</span>
                  <span>By {blog.author}</span>
                </div>

                {/* Read More Button */}
                <button className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded">
                  Read More
                </button>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default Blog;
