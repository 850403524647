import React from 'react'
import HeroSection from '../components/Hero-section'
import AboutSection from '../components/About-section'
import ServicesSection from '../components/ServicesSection'
import WhyChooseUs from '../components/Why-section'
import Testimonial from '../components/Testimonial'

const Homepage = () => {
  return (
    <div className=''>
        <HeroSection />
        <AboutSection />
        <ServicesSection />
        <WhyChooseUs />
        <Testimonial />
    </div>
  )
}

export default Homepage
