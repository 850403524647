import React from "react";
import teamImage from "../assets/unsplash_bQOQDAV7-Ak.png";

function AboutSection() {
  return (
    <section className="bg-white py-12 px-6">
      <div className="container mx-auto px-4">
        {/* Heading */}
        <div className="flex items-center justify-center text-center mx-auto">
          <h2 className="text-2xl md:text-3xl font-bold mb-4 text-gray-800">
            Who We Are
          </h2>
        </div>

        {/* Main Content */}
        <div className="flex flex-col lg:flex-row items-center gap-8">
          {/* Left Section */}
          <div className="lg:w-1/2">
            <p className="text-gray-600 mb-4 text-sm md:text-base lg:text-lg">
              At Qwiktechnologies, we are a team of passionate digital
              innovators dedicated to helping businesses thrive in the
              ever-evolving digital landscape. Our goal is to transform your
              vision into reality by providing a full suite of customized
              digital solutions that drive results and deliver lasting value.
            </p>
            <p className="text-gray-600 mb-6 text-sm md:text-base lg:text-lg">
              Whether you need a stunning website, seamless user experiences, or
              strategies to grow your brand, we are here to guide you every step
              of the way.
            </p>
            <p className="text-gray-600 mb-6 text-sm md:text-base lg:text-lg">
              Our team of seasoned designers and strategists bring a wealth of
              experience and a creative edge to every project.
            </p>
            <button className="bg-orange-500 text-white py-2 px-4 rounded-md hover:bg-orange-600 transition text-sm md:text-base">
              See More
            </button>
          </div>

          {/* Right Section */}
          <div className="lg:w-1/2">
            <img
              src={teamImage}
              alt="Team working"
              className="rounded-lg shadow-lg w-full h-auto max-w-md mx-auto lg:max-w-full"
            />
          </div>
        </div>

        {/* Stats Section */}
        <div className="mt-12 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 text-center">
          <div>
            <h3 className="text-3xl md:text-4xl font-bold text-blue-600">5+</h3>
            <h4 className="text-gray-600 text-sm md:text-base">
              Years of Experience
            </h4>
          </div>
          <div>
            <h3 className="text-3xl md:text-4xl font-bold text-blue-600">
              13k
            </h3>
            <h4 className="text-gray-600 text-sm md:text-base">
              Projects Completed
            </h4>
          </div>
          <div>
            <h3 className="text-3xl md:text-4xl font-bold text-blue-600">
              10k+
            </h3>
            <h4 className="text-gray-600 text-sm md:text-base">
              Customer Satisfaction
            </h4>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutSection;
