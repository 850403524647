import React from "react";
import bgImage from "../assets/Frame-191.png";
import AboutWhyChooseUs from "../components/AboutWhy-section";
import Newsletter from "../components/Newsletter";

const About = () => {
  return (
    <>
      <section className="bg-[#F4F5F7]">
        <div className="flex flex-col lg:flex-row justify-between text-gray-800 py-14 px-4 lg:px-10">
          <div className="flex-1 px-8 space-y-6">
            <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold leading-snug">
              Creating Websites <br /> that Convert.
            </h1>
          </div>

          <div className="flex-1 flex justify-end mt-8 lg:mt-0">
            <div className="flex-1 px-8">
              <h1 className="text-2xl sm:text-3xl font-semibold leading-snug">
                Our Mission
              </h1>
              <div className="mt-1 sm:w-2/3 lg:w-1/2 py-4">
                <p className="text-base sm:text-lg">
                  Our mission is to empower you with the tools to attract
                  clients and drive sales.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-8 w-full px-8 sm:px-12 lg:px-16 py-16">
          <p className="text-base sm:text-lg">
            We are a dynamic team of designers, developers, and digital
            strategists dedicated to providing cutting-edge website templates
            and graphic design solutions that cater to the needs of businesses
            and individuals in the digital age.
          </p>
        </div>

        <div
          className="w-full h-[300px] sm:h-[400px] lg:h-[500px] bg-cover bg-center flex items-center justify-center text-center text-white"
          style={{
            backgroundImage: `url(${bgImage})`,
          }}
        ></div>
      </section>

      <section className="bg-[#F4F5F7] text-gray-900 py-16">
        <div className="container mx-auto px-4 sm:px-8 lg:px-12">
          <h2 className="text-2xl sm:text-3xl text-center font-bold mb-8">
            The Problem We Solve
          </h2>
          <p className="text-base sm:text-lg">
            In today's fast-paced digital world, the pressure to create a
            professional online presence is higher than ever. However, many
            businesses and individuals struggle with the time, expertise, and
            resources required to design and develop websites and graphics that
            truly represent their brand. Custom design and development can be
            expensive, time-consuming, and often inaccessible to small
            businesses, startups, and freelancers.
          </p>
          <p className="text-base sm:text-lg mt-4">
            This is where we come in. We understand the challenges you
            face—whether it's limited design skills, tight deadlines, or budget
            constraints—and we are here to bridge that gap. Our comprehensive
            library of website templates and graphic assets are designed to make
            high-quality design accessible to everyone. We provide you with the
            tools to build a beautiful, functional website or create
            eye-catching graphics without the need for a large budget or
            advanced technical skills.
          </p>
        </div>
      </section>

      <AboutWhyChooseUs />
      <Newsletter />
    </>
  );
};

export default About;
