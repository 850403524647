import React, { useState } from "react";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    businessType: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.name ||
      !formData.email ||
      !formData.phoneNumber ||
      !formData.message
    ) {
      alert("Please fill in all the required fields.");
      return;
    }

    if (!/\S+@\S+\.\S+/.test(formData.email)) {
      alert("Please enter a valid email address.");
      return;
    }

    if (!/^\d+$/.test(formData.phoneNumber)) {
      alert("Please enter a valid phone number.");
      return;
    }

    try {
      const response = await fetch("https://qwik-backend-sl24.onrender.com/api/submit-form", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        alert("Your message has been submitted. We'll get back to you soon!");
        setFormData({
          name: "",
          email: "",
          phoneNumber: "",
          businessType: "",
          message: "",
        });
      } else {
        alert(data.message || "Error submitting form.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("There was an error submitting your message. Please try again.");
    }
  };

  return (
    <div className="bg-[#F4F5F7] min-h-screen">
      <section className="container mx-auto py-16">
        <h2 className="text-xl px-8 font-bold text-[#EC2A02] mb-2">
          Get In Touch
        </h2>
        <h2 className="text-3xl px-8 font-bold mb-3">
          Reach out to us to start your project
        </h2>

        <div className="p-8 rounded-lg">
          <h3 className="text-xl font-semibold mb-2">Head Office</h3>
          <p className="text-lg">
            Address: Plot 308 Asheik Jarma, 62 Road, 6th Avenue, Gwarinpa, Abuja
            FCT.
          </p>
          <p className="text-lg">Email: contact@qwiktechnologies.com</p>
          <p className="text-lg">Email: info@qwiktechnologies.com</p>
          <p className="text-lg">Phone: +234 7067586867</p>
        </div>

        <div className="p-8 rounded-lg mt-8">
          <h3 className="text-xl font-semibold mb-4">
            Let's Start a Conversation
          </h3>
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleChange}
                  className="mt-1 p-2 w-full border border-gray-400 bg-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  className="mt-1 p-2 w-full border border-gray-400 rounded-md bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div>
                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  className="mt-1 p-2 bg-gray-100 w-full border border-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div>
                <input
                  type="text"
                  id="businessType"
                  name="businessType"
                  placeholder="Business Type"
                  value={formData.businessType}
                  onChange={handleChange}
                  className="mt-1 p-2 bg-gray-100 w-full border border-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            </div>
            <div className="mt-4">
              <textarea
                id="message"
                name="message"
                placeholder="Send a message"
                value={formData.message}
                onChange={handleChange}
                rows={4}
                className="mt-1 p-2 w-full bg-gray-100 border border-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="flex justify-center mt-4">
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Contact;
