import React from "react";
import { LuCircleArrowRight } from "react-icons/lu";

const Newsletter = () => {
  return (
    <section>
      <div className="flex flex-col md:flex-row justify-between mx-auto my-12 px-6 md:px-12 lg:px-20">
        {/* Left content - Title */}
        <div className="mb-6 md:mb-0">
          <h2 className="text-2xl md:text-3xl lg:text-4xl text-blue-500 font-bold mb-4">
            Subscribe to our newsletter to get <br /> a free brand guide
          </h2>
        </div>

        {/* Right content - Form */}
        <div className="flex flex-col md:flex-row justify-center items-center gap-4 w-full md:w-auto">
          <form className="w-full md:w-96 flex flex-col md:flex-row gap-4">
            <input
              type="email"
              placeholder="Enter your email address"
              className="w-full md:w-64 px-4 py-3 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-gray-500"
            />
            <button
              type="submit"
              className="bg-blue-500 text-white py-3 px-4 rounded-md flex justify-center items-center"
            >
              <LuCircleArrowRight className="h-6 w-6" />
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Newsletter;
