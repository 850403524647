import React from "react";

function AboutWhyChooseUs() {
  return (
    <section className="bg-white pt-16">
      <div className="container mx-auto px-4">
        {/* Heading */}
        <h2 className="text-2xl md:text-3xl font-bold text-center mb-10">
          Why Choose Us
        </h2>

        {/* Cards Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="bg-[#FF5722] h-auto rounded-lg text-white shadow-md p-6">
            <h3 className="text-lg md:text-xl font-semibold mb-4">
              Modern Design
            </h3>
            <p className="text-sm md:text-base">
              Our templates are crafted by industry-leading designers, ensuring
              that your website or graphic project is always on trend and
              aligned with the latest design standards.
            </p>
          </div>

          <div className="bg-[#E5E5E5] rounded-lg shadow-md p-6">
            <h3 className="text-lg md:text-xl font-semibold mb-4">
              User-Friendly
            </h3>
            <p className="text-sm md:text-base">
              No coding or design experience? No problem. Our products are easy
              to use, with intuitive interfaces that allow you to customize your
              website or graphics with ease.
            </p>
          </div>

          <div className="bg-[#E5E5E5] rounded-lg shadow-md p-6">
            <h3 className="text-lg md:text-xl font-semibold mb-4">
              Continuous Support
            </h3>
            <p className="text-sm md:text-base">
              We are here to help at every step of your journey. From tutorials
              and guides to customer support, we ensure you have everything you
              need to succeed.
            </p>
          </div>

          <div className="bg-[#E5E5E5] rounded-lg shadow-md p-6">
            <h3 className="text-lg md:text-xl font-semibold mb-4">
              Modern Design
            </h3>
            <p className="text-sm md:text-base">
              Our templates are crafted by industry-leading designers, ensuring
              that your website or graphic project is always on trend and
              aligned with the latest design standards.
            </p>
          </div>

          <div className="bg-[#E5E5E5] rounded-lg shadow-md p-6">
            <h3 className="text-lg md:text-xl font-semibold mb-4">
              Responsive and Mobile-Optimized
            </h3>
            <p className="text-sm md:text-base">
              In a mobile-first world, we ensure that your website looks great
              on any device, providing a seamless user experience across
              desktops, tablets, and smartphones.
            </p>
          </div>

          <div className="bg-[#FF5722] h-auto rounded-lg text-white shadow-md p-6">
            <h3 className="text-lg md:text-xl font-semibold mb-4">
              Affordable Pricing
            </h3>
            <p className="text-sm md:text-base">
              High-quality design doesn't have to come with a high price tag. We
              offer competitive pricing and flexible packages to suit any
              budget.
            </p>
          </div>
        </div>

        {/* Call to Action Section */}
        <div className="mt-12 flex flex-col sm:flex-row items-center sm:justify-between gap-6 bg-gray-100 p-6 rounded-lg shadow-md">
          <h3 className="text-gray-700 font-semibold text-center sm:text-left">
            Get expert assistance whenever you need it with our dedicated
            support
          </h3>
          <button className="border border-gray-700 text-gray-800 font-semibold py-2 px-6 rounded-md hover:bg-gray-200 transition">
            Request Here
          </button>
        </div>
      </div>
    </section>
  );
}

export default AboutWhyChooseUs;
